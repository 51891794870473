import Repository from './repository'

const resource = '/v1/treinamentos/modelos'

export default {
  buscaModeloTreinamento (id) {
    return Repository.get(`${resource}/${id}`)
  },
  listarModelosTreinamento () {
    return Repository.get(`${resource}`)
  },
  salvarModeloTreinamento (item) {
    return Repository.post(`${resource}`, item)
  },
  atualizarModeloTreinamento (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  deletarModeloTreinamento (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
