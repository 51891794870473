<template>
  <div class="container-fluid">
    <Load :isLoading="isLoading" />
    <div class="card">
      <div class="card-header">
        <h3>
          Cadastro de treinamento
        </h3>
      </div>
      <div class="card-body">
        <form @submit.prevent="salvar">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label for="codigo">Código</label>
                <input type="text" name="codigo" id="codigo" class="form-control" v-model="treinamento.codigo">
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label for="nome" class="required">Tipo de treinamento</label>
                <select2 
                    :ItemsProp="modelosTreinamento"
                    :FieldProp="'nome_atividade'"
                    :PlaceholderTextProp="'Pesquisar tipo de treinamento'"
                    :ClearAfterSelectedProp="false"
                    :SelectedItemProp="modeloTreinamentoSelecionado"
                    @Selected="TipoTreinamentoSelecionado($event)">
                    
                    </select2>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group" :class="{ 'form-group--error': $v.treinamento.carga_horaria_pratica.$error }">
                <label for="carga_horaria" class="required">Carga horária prática</label>
                <input type="text"
                  class="form-control" v-mask="'##:##'" v-model="treinamento.carga_horaria_pratica" placeholder="00:00">
                <div class="error-form text-danger" v-if="!$v.treinamento.carga_horaria_pratica.required">Campo obrigatório</div>
              </div>                  
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group" :class="{ 'form-group--error': $v.treinamento.carga_horaria_teorica.$error }">
                <label for="carga_horaria" class="required">Carga horária teórica</label>
                <input type="text"
                  class="form-control" v-mask="'##:##'" v-model="treinamento.carga_horaria_teorica" placeholder="00:00">
                <div class="error-form text-danger" v-if="!$v.treinamento.carga_horaria_teorica.required">Campo obrigatório</div>
              </div>                  
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="local" class="required">Local</label>
                <select class="form-control" name="local" id="local" v-model="treinamento.treinamento_local_id">
                  <option v-for="(item, index) in locaisTreinamento" :key="index" :value="item.id">{{item.nome}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="sala" class="required">Sala</label>
                <select class="form-control" name="sala" id="sala" v-model="treinamento.treinamento_local_sala_id">
                  <option value="" v-if="salasTreinamento().length == 0" selected="selected">Selecione um local</option>
                  <option v-for="(item, index) in salasTreinamento()" :key="index" :value="item.id">{{item.sala}}</option>
                </select>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label for="data_inicio" class="required">Data de início</label>
                <input type="text"
                  class="form-control" name="data_inicio" id="data_inicio" v-mask="'##/##/####'" v-model="treinamento.data_inicio" placeholder="99/99/9999">
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <label for="data_fim" class="required">Data de término</label>
                <input type="text"
                  class="form-control" name="data_fim" id="data_fim" v-mask="'##/##/####'" v-model="treinamento.data_fim" placeholder="99/99/9999">
              </div>
            </div>
            <div class="col-md-4">
              <label for="data_fim" class="required">Horário de</label>
              <div class="row form-inline">
                <div class="col-md-12">
                  <input type="text"
                  class="form-control px-1"  v-mask="'##:##'" v-model="treinamento.hora_inicio" placeholder="99:99" >
                  <span class="px-1">às</span>
                  <input type="text"
                  class="form-control px-1" v-mask="'##:##'" v-model="treinamento.hora_fim" placeholder="99:99" >
                </div>                
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="carga_horaria" >Tempo de treinamento</label>
                <div class="input-group">
                  <input v-model="treinamento.dias_treinamento" type="number" class="form-control">
                  <div class="input-group-append">
                    <span class="input-group-text">dias</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Observação</label>
                <textarea class="form-control"  rows="7" cols="50" v-model="treinamento.observacao"></textarea>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12">
              <h5 class="float-left pr-5">Instrutores</h5>
              <button type="button" class="btn btn-outline-info p-1" style="font-size: small;" @click="addInstrutor()">Adicionar instrutor</button>
            </div>
            <div class="col-md-12 col-sm-12">
              <div class="row"  v-for="(item, index) in treinamento.instrutores" :key="index">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="nome" class="required">Instrutor</label>
                    <select2 
                    :ItemsProp="instrutores"
                    :FieldProp="'nome'"
                    :PlaceholderTextProp="'Pesquisar instrutor'"
                    :ClearAfterSelectedProp="false"
                    :SelectedItemProp="item"
                    @Selected="InstrutorSelecionado($event, index)">
                    
                    </select2>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="carga_horaria" class="required">Carga horária</label>
                    <input type="text"
                      class="form-control" v-mask="'##:##'" v-model="item.carga_horaria" placeholder="00:00">
                  </div>                  
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="responsavel_tecnico">Resp. técnico</label>
                    <select class="form-control" name="responsavel_tecnico" v-model="item.responsavel_tecnico">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>              
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="responsavel_tecnico">Instrutor</label>
                    <select class="form-control" name="responsavel_tecnico" v-model="item.instrutor">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>              
                </div>
                <div class="col-md-2 align-self-end">
                  <div class="form-group">
                    <i class="fas fa-trash-alt btn btn-danger" title="Deletar instrutor" @click="deletarItem(index)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-5">
              <button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
              <button type="submit" class="btn btn-primary float-right">SALVAR</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import select2 from './../../uicomponents/select2'
import treinamentoRepository from './../../services/api/treinamentoRepository'
import treinamentoModelosRepository from './../../services/api/treinamentoModelosRepository'
import locaisTreinamentosRepository from './../../services/api/locaisTreinamentosRepository'
import instrutoresRepository from './../../services/api/instrutoresRepository'
export default {
  name: 'TreinamentoCadastro',
  components: {
    select2
  },
  validations: {
    treinamento: {
      data_inicio: {
        required
      },
      treinamento_modelo_id: {
        required
      },
      treinamento_local_id: {
        required
      },
      treinamento_local_sala_id: {
        required
      },
      data_fim: {
        required
      },
      carga_horaria_teorica: {
        required
      },
      carga_horaria_pratica: {
        required
      }
    }
  },
  data () {
    return {
      isLoading: false,
      modeloTreinamentoSelecionado: null,
      locaisTreinamento: [],
      modelosTreinamento: [],
      instrutores: [],
      treinamento: {
        treinamento_modelo_id: null,
        treinamento_local_id: null,
        treinamento_local_sala_id: null,
        data_inicio: null,
        data_fim: null,
        carga_horaria_pratica: null,
        carga_horaria_teorica: null,
        instrutores: [],
        treinamento_modelo: null,
        dias_treinamento: null
      }
    }
  },
  created () {
    this.fetch()
	},
  methods: {
    async fetch () {
      this.isLoading = true
      let responseModelos = await treinamentoModelosRepository.listarModelosTreinamento()
      if (responseModelos.data['success']) {
        this.modelosTreinamento = responseModelos.data['data']
      }

      let responseLocais = await locaisTreinamentosRepository.listarLocais()
      if (responseLocais.data['success']) {
        this.locaisTreinamento = responseLocais.data['data']
      }

      let responseInstrutores = await instrutoresRepository.listarInstrutores()
      if (responseInstrutores.data['success']) {
        this.instrutores = responseInstrutores.data['data']
      }

      if (this.$route.query.id) {
        this.buscarTreinamento(this.$route.query.id)
      }
      this.isLoading = false
    },
    addInstrutor () {
      let item = {
        responsavel_tecnico: 0,
        instrutor: 0,
        carga_horaria: 0
      }
      this.treinamento.instrutores.push(item)
    },
    InstrutorSelecionado(instrutor, index) {
      if (instrutor !== null) {
        this.treinamento.instrutores[index].treinamento_id = this.treinamento.id
        this.treinamento.instrutores[index].instrutor_id = instrutor.id        
      }
    },
    TipoTreinamentoSelecionado(tipoTreinamento) {
      if (tipoTreinamento !== null) {
        this.treinamento.treinamento_modelo_id = tipoTreinamento.id
        this.treinamento.carga_horaria_teorica = tipoTreinamento.carga_horaria_teorica
        this.treinamento.carga_horaria_pratica = tipoTreinamento.carga_horaria_pratica
      }
    },
    salasTreinamento () {
      let salas = []
      if(this.treinamento.treinamento_local_id !== null) {
        let local = this.locaisTreinamento.find(x => x.id == this.treinamento.treinamento_local_id)
        salas = local.salas
      }
      return salas
    },
    buscarTreinamento (idTreinamento) {
      treinamentoRepository.buscaTreinamentoPorId(idTreinamento).then(response => {
				if(response.data['success']) {
          this.treinamento = response.data['data']
          this.treinamento.data_fim = this.treinamento.data_fim.split('-').reverse().join('/')
          this.treinamento.data_inicio = this.treinamento.data_inicio.split('-').reverse().join('/')
          this.modeloTreinamentoSelecionado = this.modelosTreinamento.find(x => x.id == this.treinamento.treinamento_modelo_id)
        }
			})
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'Treinamentos' })
    },
    salvar () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha os campos obrigatórios!',
        })
      } else {
        if(this.validaCargaHoraria()) {
          this.$swal({
            icon: 'warning',
            text: 'Total de horas de instrutor e do treinamento estão diferentes!'
          })
          return
        } else {
          let treinamento = Object.assign({}, this.treinamento)
          treinamento.data_fim = treinamento.data_fim.split('/').reverse().join('-')
          treinamento.data_inicio = treinamento.data_inicio.split('/').reverse().join('-')
          if (treinamento.id) {
            treinamentoRepository.atualizarTreinamento(treinamento.id, treinamento).then(response => {
              if (response.data['success']) {
                this.$swal({
                  icon: 'success',
                  text: 'Cadastro atualizado com sucesso!'
                }).then( () => {
                  this.goBack()
                })
              }
            }).catch( error => {
              console.log(error)
              this.$swal({
                icon: 'error',
                text: 'Não foi possível realizar o cadastro!'
              })
            })
          } else {
            treinamentoRepository.salvarTreinamento(treinamento).then(response => {
              if (response.data['success']) {
                this.$swal({
                  icon: 'success',
                  text: 'Cadastro atualizado com sucesso!'
                }).then( () => {
                  this.goBack()
                })
              }
            }).catch(error => {
              console.log(error)
              this.$swal({
                icon: 'error',
                title: 'Erro no cadastro!',
                text: error.message
              })
            })
          }
        }
      }
    },
    validaCargaHoraria () {
      if(this.treinamento.instrutores.length == 0) {
        return false
      } else {
        let totalCargaInstrutores = 0
        this.treinamento.instrutores.map(obj => {
          let carga = obj.carga_horaria.substring(0, 5).replace(':','')
          totalCargaInstrutores += parseFloat(carga)
        })
        let cargaCurso = parseFloat(this.treinamento.carga_horaria_pratica.substring(0, 5).replace(':','')) + parseFloat(this.treinamento.carga_horaria_teorica.substring(0, 5).replace(':',''))
        return cargaCurso != totalCargaInstrutores
      }
    },
    modelosTreinamentoChange () {
      this.treinamento.treinamento_modelo_id = this.modeloTreinamentoSelecionado.id
      this.treinamento.carga_horaria_teorica = this.modeloTreinamentoSelecionado.carga_horaria_teorica
      this.treinamento.carga_horaria_pratica = this.modeloTreinamentoSelecionado.carga_horaria_pratica
    },
    deletarItem (index) {
      this.treinamento.instrutores.splice(index, 1)
    }
  }
}
</script>

<style>

</style>